import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  connect() {
    const popup = $(this.element)

    let delay = popup.data("delay") // seconds
    let showOnlyOnce = popup.data("show-only-once") // false for test

    const showPopup = !showOnlyOnce || !Cookies.get("popup_shown")

    // const fiveSec = new Date(new Date().getTime() + 5 * 1000);

    if (showPopup){
      setTimeout(() => {
        $(popup).modal('show')
        sessionStorage.setItem("popup_show", true)
        Cookies.set('popup_shown', true, { expires: 1 })
      }, delay * 1000);
    }
  }
}